<template>
<div>
  <ayl-berad-nav :nav="nav"></ayl-berad-nav>
  <div class="clearBox">
    <div>
      <div style="center-video">
        <el-row class="videoCol">
          <el-col :span="8" style="padding: 10px; height:320px;" v-for="(item, idx) in allVideoUrl" :key="idx">
            <ayl-rtmp :style="mainStyle" :url="item" ref="rtmp" style="width: 95%; height: 100%"/>
            <!-- <video-play :url='item'></video-play> -->
            <!-- <div style="display: inline-block;line-height: 40px;height: 40px;margin-right: 10px">{{item.value}}</div> -->
          </el-col>
          <el-col :span="24" v-if="allVideoUrl.length !== 0" style="padding: 8px; background-color: #fff;">
            <div class = "video-item">
              <div>
                <i class="iconfont curp" v-for="(item, idx) in iconList"
                  :class="[item.name, screenNum===item.value?'active-icon': '']"
                  :key="idx"
                  style="padding: 0 10px"
                  @click="clickSwitch(item.value)"></i>
              </div>
            </div>
            <div class="video-item" style="float: right;margin-right: 10px;">
              <span v-for="(item, idx) in operationList" :key="idx" class="operation-item curp">{{item}}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
  import utils from '@/utils/func'
  import videoPlay from './components/videoPlay'
  import AylRtmp from './components/AylRtmp'

  const LineIcon = require('../../../assets/images/map-icon (1).png')
  const offLineIcon = require('../../../assets/images/map-icon (2).png')
  const onLineIcon = require('../../../assets/images/map-icon (3).png')
  export default {
    name: 'BusImg',
    components: {
      videoPlay,
      AylRtmp,
    },
    data () {
      return {
        nav: [{name: '实时视频'}],
        videoUrl: [],
        carCheckList: [],
        allCheckUrl: [],
        operationList: ['字幕', '云台', '色彩', '比例', '画质', '音量', '设置'],
        // 视频数据
        //spanItem: 8,
        screenNum: 9,
        allVideoUrl: [],

        iconList: [{
          name: 'iconicon-test2',
          value: 1
        }, {
          name: 'iconicon-test3',
          value: 4
        }, {
          name: 'iconicon-test9',
          value: 9
        }],
      }
    },
    methods: {
      // 切换屏幕
      clickSwitch (v) {
        if (this.allVideoUrl.length === 0) return
        if (v === this.screenNum) return
        this.allVideoUrl = []
        this.screenNum = v
        this.checkClick()
      },
      // eslint-disable-next-line no-unused-vars
      async checkClick (value) {
        if (!this.unFlash()) {
          this.$notify({
            title: '系统警告',
            message: '系统检测到该网页未允许运行flash,这会导致视频无法播放,请在设置中打开flash',
            type: 'warning'
          });
          // this.$refs.tree.setCheckedNodes([])
          return
        }
 
        //获取勾选车辆的所有通道url
        this.allVideoUrl = []
        let _allVideoUrl = await this.$api.getRtmpUrls({
          deviceId: this.$route.query.deviceId,
          channelList: Array.from({length: this.screenNum}, (v, i) => i + 1),
          dataType: 0,
          streamType: 0,
          //tenantId: this.carList[0].tenantId
        })
        this.allVideoUrl = Object.keys(_allVideoUrl).map((k) => _allVideoUrl[k])
        //  console.log(this.allVideoUrl)
        // this.videoUrl = this.allCheckUrl
      },

      // 检测网页是否启用了Flash
      unFlash(){
        let flashObj;
        if(typeof window.ActiveXObject != "undefined"){
          // eslint-disable-next-line no-undef
          flashObj = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
        }else{
          flashObj = navigator.plugins['Shockwave Flash'];
        }
        return flashObj ? true : false;
      },
    },

    computed: {
      mainStyle () {
        if (this.screenNum === 4) return this.spanItem === "12"
        if (this.screenNum === 9) return this.spanItem === "8"
        return this.spanItem === "24"
      }
    },

    async mounted () {
      this.checkClick()
      // this.carData = await this.$api.getRealTimeCarInfoList({})
      // this.testAjax()
    },
  }
</script>

<style lang='sass' scoped>
  .clearBox
    height: 100%
    overflow: auto
    /deep/ .el-radio__label
      display: inline-block

    .radio-img
      width: 48px

    /deep/ .el-form--inline .el-form-item
      margin-right: 10px

    /deep/ .el-tree
      padding-bottom: 21px

    /deep/ .el-tree-node__content
      height: 30px

    /deep/ .el-input
      width: 100%

    /deep/ .el-tree-node__label
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

    .carsVideoLeft
      max-width: 300px
      width: 300px
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      // padding: 25px 14px
      margin-right: 10px
      display: inline-block
      vertical-align: top
      overflow: auto

    .carsVideoRight
      width: calc(100% - 310px)
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      padding: 25px 14px
      display: inline-block
      vertical-align: top

      .setting
        text-align: right

    .center-btn
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      width: 100%

    .center-video
      margin-top: 20px


    .videoCol
      padding-top: 20px
      padding-bottom: 70px

      .videoAisle
        padding: 10px 0 20px
        display: inline-block
        vertical-align: middle
        font-size: 12px
        float: right

      .videoLicense
        padding-left: 15px
        display: inline-block
        vertical-align: -webkit-baseline-middle
        font-size: 12px
        line-height: 50px
  .video_perspective
    float: right
    width: 180px
    margin: 5px 0
  /deep/ .el-button--mini.is-round
    padding: 4px 12px

  .video-item
    display: inline-block
    vertical-align: middle
  .active-icon
    color: $-color-primary-2
  .operation-item
    display: inline-block
    width: 42px
    height: 20px
    border-radius: 10px
    font-size: 12px
    text-align: center
    line-height: 20px
    border: 1px solid $grayShallow2
    transition: .35s

    & + .operation-item
      margin-left: 10px

    &:hover
      border-color: $-color-primary-2
      @extend .active-icon

</style>
<style scoped>
  .carsVideoHeight {
    height: calc(80vh)
  }
</style>
