<template>
  <div class="ayl-video" ref="aylVideo">
    <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
  </div>
</template>

<script>
  import videojs from '../../../../utils/video'
  import video_zhCN from '../../../../utils/zh-CN.json'
  import 'video.js/dist/video-js.css'
  import 'vue-video-player/src/custom-theme.css'  

  export default {
    props: ['url'],
    data () {
      return {
        player: null,
      }
    },
    watch: {
      // url: {
      //   handle () {
      //     this.player.pause()
      //     this.player.src(this.url)
      //     this.player.load(this.url)
      //   }
      // }
      // "viewIndex":function () {
      //   this.player.width(this.$refs.aylVideo.clientWidth);
      //   this.player.height(this.$refs.aylVideo.clientHeight);
      // }
    },
    methods: {
      initVideo () {
        // videojs.options.flash.swf = "./video-js.swf";
        this.player = videojs(this.$refs.videoPlayer, {
          autoplay: true,
          controls: true,
          muted: true,
          language: 'zh-CN',
          height: '300px',
          width: this.$refs.aylVideo.clientWidth,
          techOrder: ['flash'],
          preload: 'auto',
          flash: {
            // hls:{
            //   w
            // }
            // swf: 'video-js.swf',
          },
          sources: [
            {
              src: this.url,
              // src: 'rtmp://47.100.202.0/ccav/111122224444-3',
              type: 'rtmp/flv'
            }
          ],
        })
        videojs.addLanguage('zh-CN', video_zhCN)
        this.player.play()
      }
    },
    mounted () {
      this.initVideo()
    },
    beforeDestroy () {
      if (this.player) {
        this.player.dispose()
      }
    },
    deactivated () {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    activated () {
      // this.initVideo()
      // console.log(this.$refs.aylVideo)
      // console.log(this.url)
      // this.player = null
      // this.initVideo()
    },
  }
</script>

<style lang='sass' scoped>
  .ayl-video
    display: inline-block
</style>
